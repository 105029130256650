import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { ToastContainer } from 'react-toastify';
import LoginPage from "./pages/LoginPage/LoginPage";
// import createHistory from 'history/createBrowserHistory';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';
import { RootState } from './models/RootState';

import AcceptTerms from './pages/AcceptTerms/AcceptTerms';
import MailVerificationAlert from './components/MailVerificationAlert/MailVerificationAlert';
import { fetchMailVerifiedSuccess, logout } from './store';
import ResetPassword from './pages/ResetPasswordPage/ResetPasswordPage';
import AccessRedirect from './pages/AccessRedirect/Accessredirect';
import SignUp from './pages/SignUp/SignUp';

const Admin = lazy(() => import('./router/AdminRouter'));
const MainPage = lazy(() => import('./router/UserRouter'));
const Platform = lazy(() => import('./router/PlatFormAdminRouter'));


type StateProps = {
  fetchMailVerifiedSuccess: (mailVerified: boolean) => void;
  logout: () => void;
  mailVerified: boolean;
  prefersDark: boolean;
  loggedIn: boolean;
};

const App: React.FC<StateProps> = (props) => {
  const [mvState] = useState<boolean>(null);
  // const [idleTime, setIdleTime] = useState<number>(1000 * 60 * 30); // 30 minutes
  const consoleOpen = useConsoleOpen();

  const { logout, prefersDark, loggedIn } = props;

  useEffect(() => {
    const toggleTheme = (systemInitiatedDark) => {
      if (systemInitiatedDark.matches) {
        document.body.setAttribute('data-theme', 'dark');
      } else {
        document.body.setAttribute('data-theme', 'light');
      }
    };

    let systemDark = window.matchMedia('(prefers-color-scheme: dark)');
    systemDark.addListener((e) => toggleTheme(prefersDark));
    document.body.classList.toggle('dark', prefersDark);
  }, [prefersDark]);

  const handleOnIdle = () => {
    console.log('user is idle');
    if (loggedIn) {
      logout();
    }
  };

  const handleClose = () => {
    console.log('user open devtools');
  };

  // useEffect(() => {
  //   if (loggedIn) {
  //     const role = JSON.parse(
  //       window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
  //     ).role;
  //     if (role !== 'User') setIdleTime(1000 * 60 * 60 * 8);
  //     else setIdleTime(1000 * 60 * 30);
  //   }
  // }, [loggedIn]);

  useIdleTimer({
    timeout: 1000 * 60 * 60 * 8,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <>
      {!consoleOpen ? (
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <Switch>
              <Route path="/access_redirect" component={AccessRedirect} />
              <Route path="/admin" component={Admin} />
              <Route path="/login" component={LoginPage} />
              <Route path="/register" component={SignUp} />
              <Route path="/platform_admin" component={Platform} />
              <Route path="/terms-and-conditions" component={AcceptTerms} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/" component={MainPage} />
            </Switch>
            {mvState === false ? (
              <MailVerificationAlert message="Verify your email"></MailVerificationAlert>
            ) : null}
          </BrowserRouter>
        </Suspense>
      ) : null}

      <Dialog
        open={consoleOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        className="dev-tools-msg-modal"
      >
        <DialogContent className="modal-content-ctn">
          <div className="dev-tools-warning-msg">
            {' '}
            Due to some inactivity or security reasons stop your website, please
            close the developer tool. Thank you for your support
          </div>
        </DialogContent>
      </Dialog>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export const useConsoleOpen = () => {
  const [consoleOpen, setConsoleOpen] = useState(false);

  useEffect(() => {
    let checkStatus;
    var element = new Image();
    Object.defineProperty(element, 'id', {
      get: function () {
        checkStatus = true;
        throw new Error('Dev tools checker');
      },
    });

    requestAnimationFrame(function check() {
      setTimeout(() => {
        checkStatus = false;
        // Don't delete this console statements
        // if you want dev tools just comment this below two lines.

        if (process.env.REACT_APP_NODE_ENV !== 'development') {
          // console.clear();
          // console.dir(element);
        }

        setConsoleOpen(checkStatus);
        requestAnimationFrame(check);
      }, 1000);
    });
  });

  return consoleOpen;
};

const mapStateToProps = (state: RootState) => {
  return {
    mailVerified: state.auth.mailVerified,
    prefersDark: state.common.prefersDark,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchMailVerifiedSuccess: (mailVerified: boolean) =>
      dispatch(fetchMailVerifiedSuccess(mailVerified)),
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
